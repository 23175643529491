import withStyles from "@material-ui/core/styles/withStyles";
import Slider from "@material-ui/core/Slider";

export const CustomSlider = withStyles({
    root: {
        color: '#1cb8af',
        height: 0,
    },
    thumb: {
        height: 15,
        width: 15,
        backgroundColor: '#ffffff',
        border: '3px solid currentColor',
    },
    valueLabel: {
        left: 'calc(-50% - 7px)',
    },
    track: {
        height: 5,
        borderRadius: 2,
    },
    rail: {
        height: 5,
        borderRadius: 2,
    },
})(Slider);