import React, {Component} from 'react';
import './App.css';
import Settings from "./components/Settings/Settings";
import MountainPanorama from "./components/MountainPanorama/MountainPanorama";

class App extends Component{

    constructor(props) {
        super(props);
        this.state = {
            randomSeed: Math.random().toString(36).substring(7),
            numMountains: 15,
            topIsolation: 200,
            topProminence: 150,
            settingsVisible: true,
            hueSelection: { h: Math.random()*360, s: 0, l: 0 },
            mountainType: "wavy"
        }
    }

    handleNumberOfMountainsChange = (event, value) => {
        this.setState({ numMountains: value });
    };

    handleTopographicIsolationChange = (event, value) => {
        this.setState({ topIsolation: value });
    };

    handleTopographicProminenceChange = (event, value) => {
        this.setState({ topProminence: value });
    };

    handleHueSelectionChange = (color) => {
        this.setState({ hueSelection: color.hsl });
    };

    handleMountainTypeChange = (event, value) => {
        if(value !== null)
            this.setState({ mountainType: value });
    };

    handleGenerateNewMountainsClick = () => {
        this.setState({ randomSeed: Math.random().toString(36).substring(7) });
    };

    toggleSettings = () => {
        this.setState({settingsVisible: !this.state.settingsVisible});
    };

    render() {
        return (
            <div className="App" >
                <MountainPanorama
                    randomSeed={this.state.randomSeed}
                    numMountains={this.state.numMountains}
                    topIsolation={this.state.topIsolation}
                    topProminence={this.state.topProminence}
                    hueSelection={this.state.hueSelection}
                    mountainType={this.state.mountainType}
                    onClick={this.toggleSettings}
                />
                {this.state.settingsVisible ? <Settings
                    onNumberOfMountainsChange={this.handleNumberOfMountainsChange}
                    onTopographicIsolationChange={this.handleTopographicIsolationChange}
                    onTopographicProminenceChange={this.handleTopographicProminenceChange}
                    onHueSelectionChange={this.handleHueSelectionChange}
                    onMountainTypeChange={this.handleMountainTypeChange}
                    onGenerateNewMountainsClick={this.handleGenerateNewMountainsClick}

                    numberOfMountains={this.state.numMountains}
                    topographicIsolation={this.state.topIsolation}
                    topographicProminence={this.state.topProminence}
                    hueSelection={this.state.hueSelection}
                    mountainType={this.state.mountainType}
                /> : "" }
            </div>
        );
    }
}

export default App;
