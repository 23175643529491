import React, {useEffect, useRef} from "react";
import SimplexNoise from "simplex-noise";
import useWindowDimensions from "./useWindowsDimensions";

const MountainPanorama = (props) => {
    const canvasRef = useRef(null);
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');
        drawMountains(ctx, props.randomSeed, props.numMountains, props.topIsolation, props.topProminence, props.hueSelection, props.mountainType);
    });

    return (
        <div className="MountainPanorama">
            <canvas
                id="canvas"
                ref={canvasRef}
                width={width}
                height={height}
                onClick={props.onClick}
            />
        </div>
    );
}

export default MountainPanorama;

function drawMountains(ctx, randomSeed, numMountains, topIsolation, topProminence, hueSelection, mountainType) {
    // TODO: Add Moving backwards/forward mode? (Rolling mountains)
    // Fill background
    let gradient = ctx.createLinearGradient(0, 0, 0, window.innerHeight / 3);
    gradient.addColorStop(0.2, "#bee3f8");
    gradient.addColorStop(1, "#27ade3");
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);

    // TODO: Add Sun?

    // Initialize Random-Generators
    let simplex = new SimplexNoise(randomSeed);

    // Draw each mountain range one by one
    for (let j = 0; j < numMountains; j++) {
        let endX = 0,
            endY = 0,
            midX = 0,
            midY = 0,
            startX = (simplex.noise2D(j,j)-1)*150,
            startY = window.innerHeight / 3 + (window.innerHeight / 2 / numMountains) * j;
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        let nextStep = topIsolation;

        // Draw each step (topological isolation) of a mountain range one by one
        while(endX < window.innerWidth){
            let step = nextStep;
            if(step !== topIsolation){
                nextStep = topIsolation;
            } else if(simplex.noise2D(endX, (j+1)*200) > 0.4){
                nextStep = topIsolation*1.3;
            } else if (simplex.noise2D(endX, (j+1)*200) < -0.4){
                nextStep = topIsolation/1.3;
            }
            endX = startX + step;
            midX = startX + step/2;
            endY = startY + (simplex.noise2D(midX, (j+1) * 100) * topProminence + simplex.noise2D(midX+(nextStep+step)/2, (j+1) * 100) * topProminence) / 2;
            midY = startY + simplex.noise2D(midX, (j+1) * 100) * topProminence;
            if(mountainType === "wavy")
                ctx.quadraticCurveTo(midX, midY, endX, endY);
            else
                ctx.lineTo(endX, endY);
            startX = endX;
        }
        ctx.lineTo(window.innerWidth, window.innerHeight);
        ctx.lineTo(0, window.innerHeight);

        // Color mountain range
        // TODO: Add snow?
        gradient = ctx.createLinearGradient(10, startY, 12, startY + 100);
        let hue1 = hueSelection.h;
        let hue2 = hue1 + 5;
        let sat1 = 30 * ((1 / 4 * numMountains) + j / 3);
        let sat2 = sat1 * 1.2;
        let lum1 = 7 * (10 / (j + 1));
        let lum2 = lum1 * 1.3;
        gradient.addColorStop(1, `hsl(${hue1},${sat1}%,${lum1}%)`);
        gradient.addColorStop(0, `hsl(${hue2},${sat2}%,${lum2}%)`);
        ctx.fillStyle = gradient;
        ctx.fill();
    }
}