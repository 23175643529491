import React from "react";
import reactCSS from "reactcss";

export const SliderPointer = ({ direction }) => {
    const styles = reactCSS({
        'default': {
            picker: {
                width: '9px',
                height: '9px',
                borderRadius: '50%',
                border: '3px solid #1cb8af',
                transform: 'translate(-6px, -5px)',
                backgroundColor: '#ffffff'
            },
        },
        'vertical': {
            picker: {
                transform: 'translate(-3px, -9px)',
            },
        },
    }, { vertical: direction === 'vertical' })

    return (
        <div style={ styles.picker } />
    )
}

export default SliderPointer